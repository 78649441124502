import axios from "@/axios.config.js";

const marketDataIndex = () => axios.get('/api/admin/v1/market_data')

const postMarketData = (body) => axios.post('/api/admin/v1/market_data',body);

const deleteMarketData = (id) => axios.delete(`/api/admin/v1/market_data/${id}`);

const updateMarketData = (id,body) => axios.post(`/api/admin/v1/market_data/${id}/update`,body);

const exportMarketData = (id) => axios.post(`/api/admin/v1/market_data/${id}/export`,{
  responseType: 'blob'
})

export { postMarketData, marketDataIndex, exportMarketData,deleteMarketData,updateMarketData };