<template>
  <ContentWrapper>
    <template v-slot:title>Create Game</template>
    <Container>
      <Form @submit="createGame">
        <div class="form-wrapper">
          <!-- Game Type -->
          <div class="game-type">
            <p class="title">Game Type</p>
            <div class="game-type-tab">
              <div
                class="public-game tab"
                :class="{ active: game.type == 'PUBLIC' }"
                @click="game.type = 'PUBLIC'"
              >
                <img src="../../../../../assets/icons/public.png" alt="globe" />
                <p>Public Game</p>
              </div>
              <div
                class="private-game tab"
                :class="{ active: game.type == 'PRIVATE' }"
                @click="game.type = 'PRIVATE'"
              >
                <img src="../../../../../assets/icons/lock.png" alt="lock" />
                <p>Private Game</p>
              </div>
            </div>
          </div>

          <!-- Game Password -->
          <Input
            id="password"
            type="password"
            placeholder="Password"
            label="Game Room Password*"
            class="bottom-spacing"
            autoComplete="new-password"
            rules="required"
            @change="updateData($event, 'password')"
            v-if="game.type == 'PRIVATE'"
          />

          <!-- Game Name -->
          <Input
            id="game name"
            type="text"
            placeholder="Game Name"
            :label="gameNameLabel"
            class="bottom-spacing span-width"
            rules="required|max:50"
            maxLength="50"
            @change="updateData($event, 'name')"
          />

          <!-- Game Picture -->
          <p class="title">Game Picture</p>
          <div class="upload-row">
            <input
              type="file"
              accept="image/*"
              id="gamePicture"
              @change="uploadAttachments"
              hidden
            />
            <label for="gamePicture" class="uploadBtn">Upload</label>
            <p>{{ attachmentName }}</p>
          </div>

          <!-- Exchange -->
          <DropdownSelect
            id="exchange"
            label="Exchange*"
            :options="this.returnExchangeObject"
            class="bottom-spacing span-width"
            @change="updateDropdownData($event, 'exchange')"
            rules="required"
          />

          <!-- Product -->
          <DropdownSelect
            id="product"
            label="Product*"
            :options="this.returnProductObject"
            class="bottom-spacing span-width"
            @change="updateDropdownData($event, 'product')"
            rules="required"
          />

          <!-- Market Data -->
          <DropdownSelect
            id="market data"
            label="Market Data*"
            :options="this.marketDataList"
            class="bottom-spacing span-width"
            @change="updateData($event, 'market_data_id')"
            rules="required"
          />

          <!-- Preview Duration -->
          <Input
            id="preview duration"
            type="text"
            placeholder="Preview Duration"
            label="Preview Duration* (Days)"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'preview_duration')"
          />

          <!-- Initial Cash Balance -->
          <Input
            id="initial cash balance"
            type="text"
            placeholder="Initial Cash Balance"
            label="Initial Cash Balance*"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'initial_cash')"
          />

          <!-- Currency -->
          <Input
            id="currency"
            type="text"
            placeholder="Currency"
            label="Currency*"
            class="bottom-spacing span-width"
            rules="required|alpha"
            @change="updateData($event, 'currency')"
          />

          <!-- Max Position Limit -->
          <Input
            id="max position"
            type="numeric"
            placeholder="No of positions"
            label="Max Net Position*(0 if unlimited)"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'max_position')"
          />

          <!-- Duration -->
          <Input
            id="duration"
            type="text"
            placeholder="No. of days"
            label="Duration (Days)*"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'duration')"
          />

          <!-- Max Players -->
          <Input
            id="max players"
            type="text"
            placeholder="No Of Players"
            label="Maximum No. of Players* (Max 100)"
            class="bottom-spacing span-width"
            maxLength="3"
            rules="required|numeric|max_value:100"
            @change="updateData($event, 'max_players')"
          />

          <!-- Timer -->
          <Input
            id="timer"
            type="text"
            placeholder="No. of Seconds"
            label="Timer* (Second)"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'timer')"
          />

          <Message :class="{ error: this.error }">{{ this.error }}</Message>
          <div class="btn-row flex">
            <SubmitButton :spacerRight="true">Create</SubmitButton>
            <SubmitButton
              :isDarkTheme="true"
              @onButtonClick="goBack"
              type="button"
              >Cancel</SubmitButton
            >
          </div>
        </div>
      </Form>
    </Container>
  </ContentWrapper>
</template>

<script>
import Form from "@/components/Form";
import Input from "@/components/Input";
import SubmitButton from "@/components/SubmitButton";
import DropdownSelect from "@/components/DropdownSelect";
import Message from "@/components/Message";

//API
import { gameStore } from "../../../../../services/adminAPI/game";
import { attachmentStore } from "../../../../../services/api/attachment";
import { productIndex } from "../../../../../services/api/product";
import { exchangeIndex } from "../../../../../services/api/exchange";
import { marketDataIndex } from "../../../../../services/adminAPI/marketData";

export default {
  components: {
    Form,
    Input,
    SubmitButton,
    DropdownSelect,
    Message,
  },
  async created() {
    const exchangeIndexAPI = await exchangeIndex();
    this.exchangeList = exchangeIndexAPI.data.response.exchanges;

    const marketDataIndexAPI = await marketDataIndex();
    this.marketDataList = marketDataIndexAPI.data.response.marketData;
  },
  data() {
    return {
      gameNameLabel: "Game Name* (0/50)",
      attachmentName: "",
      marketDataList: [],
      productList: [],
      exchangeList: [],
      error: "",
      game: {
        name: "",
        type: "PUBLIC",
        initial_cash: undefined,
        duration: undefined,
        timer: undefined,
        currency: "",
        max_position: undefined,
        product: undefined,
        exchange: undefined,
        preview_duration: undefined,
        picture: undefined,
        password: "",
        max_players: undefined,
        market_data_id: undefined,
      },
      apiIsCalling: false,
    };
  },
  methods: {
    updateData(data, attr) {
      this.game[attr] = data;
    },
    async updateDropdownData(data, attr) {
      if (attr == "exchange") {
        this.game[attr] = this.exchangeList[data];

        const productIndexAPI = await productIndex(this.game.exchange);

        this.productList = productIndexAPI.data.response.products;
      } else {
        this.game[attr] = this.productList[data];
      }
    },
    async createGame() {
      if (this.apiIsCalling) return;
      this.error = "";

      if (this.game.picture == undefined) {
        delete this.game.picture;
      }

      try {
        this.apiIsCalling = true;
        await gameStore(this.game);
        await this.$store.dispatch("callGameList");
        this.apiIsCalling = false;

        this.$swal({
          icon: "success",
          title: "Game Created",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        }).then(() => {
          this.$router.push({ name: "AdminHome" });
        });
      } catch (error) {
        this.error = error.response.data.userMessage;
        this.apiIsCalling = true;
      }
    },
    async uploadAttachments(event) {
      const file = event.target.files;
      const filename = event.target.files[0].name;
      const fileType = event.target.files[0].type;

      //Check file validity
      if (filename.lastIndexOf(".") <= 0 || !fileType.includes("image")) {
        return this.$swal({
          title: "Invalid Attachment",
          text: "File Type Not Allowed",
          icon: "error",
        });
      } else if (filename.split(".").length > 2) {
        return this.$swal({
          title: "Invalid Attachment",
          text: "File must not include more than 1 period",
          icon: "error",
        });
      }

      const data = new FormData();
      data.append("attachment", file[0]);

      //Upload Attachment
      try {
        const response = await attachmentStore(data);
        this.attachmentName = filename;
        this.game.picture = response.data.response.filename;
      } catch (error) {
        return this.$swal({
          title: "Oops",
          icon: "error",
          text: error.response.data.userMessage,
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    returnExchangeObject() {
      return this.exchangeList.map(function(data, index) {
        return {
          id: index,
          name: data,
        };
      });
    },
    returnProductObject() {
      return this.productList.map(function(data, index) {
        return {
          id: index,
          name: data,
        };
      });
    },
    gameNameLength() {
      return this.game.name.length;
    },
  },
  watch: {
    gameNameLength(data) {
      this.gameNameLabel = `Game Name* (${data}/50)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  min-height: calc(100vh - 60px);

  @media screen and (max-width: 450px) {
    height: calc(100vh - 60px);
  }
}

.form-wrapper {
  max-width: 600px;
  @media screen and (max-width: 450px) {
    margin-bottom: 1rem;
  }
}

p {
  font-size: 18px;
}

.error {
  margin-bottom: 2rem;
  text-align: left;
}

/deep/ .input-container label,
.title {
  font-size: 18px;

  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
}

.upload-row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 3px;
}

.uploadBtn {
  padding: 10px 16px;
  background-color: var(--gray414241);
  color: white;
  display: table;
  margin-right: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.game-type {
  margin-bottom: 2rem;

  .game-type-tab {
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: white;
    overflow: hidden;
    margin-top: 5px;
    width: max-content;

    .private-game,
    .public-game {
      background: var(--gray414241);
    }

    .tab {
      display: inherit;
      align-items: center;
      padding: 10px 15px;
      padding-right: 20px;
      cursor: pointer;

      &.active {
        background: var(--redC41230);
      }

      img {
        height: 20px;
        width: 20px;
        margin-right: 15px;

        @media screen and (max-width: 450px) {
          height: 15px;
          width: 15px;
          margin-right: 10px;
        }
      }

      p {
        color: white;

        @media screen and (max-width: 450px) {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
