import axios from "@/axios.config.js";

const gameShow = (gameId) => axios.get(`/api/admin/v1/games/${gameId}`);

const gameStore = (body) => axios.post('/api/admin/v1/games',body);

const gameUpdate = (gameId,body) => axios.put(`/api/admin/v1/games/${gameId}`,body);

const gameStart = (gameId) => axios.post(`/api/admin/v1/games/${gameId}/start`);

const gamePause = (gameId) => axios.post(`/api/admin/v1/games/${gameId}/pause`);

const gameResume = (gameId) => axios.post(`/api/admin/v1/games/${gameId}/resume`);

const gameReset = (gameId) => axios.post(`/api/admin/v1/games/${gameId}/reset`);

const marketDataPoints = (gameId) => axios.get(`/api/admin/v1/games/${gameId}/market_data_points`);

const latestMarketDataPoints = (gameId) => axios.get(`/api/admin/v1/games/${gameId}/latest_market_data_point`)

const deleteGame = (gameId) => axios.delete(`/api/admin/v1/games/${gameId}`)

const setGameActive = (gameId,state) => axios.post(`api/admin/v1/games/${gameId}/set_active`,state);

const gameShowLeaderboard = (gameId) => axios.post(`/api/admin/v1/games/${gameId}/show_leaderboard`);

const gameUpdateOrder = (body) => axios.put(`/api/admin/v1/games/update_orders`,body);

export {
  gameShow,
  gameStore,
  gameUpdate,
  gameStart,
  gamePause,
  gameResume,
  gameReset,
  marketDataPoints,
  latestMarketDataPoints,
  deleteGame,
  setGameActive,
  gameShowLeaderboard,
  gameUpdateOrder
}
