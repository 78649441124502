import axios from "@/axios.config.js";

const productIndex = (exchange) => axios.get('/api/v1/products',{
  params:{
    exchange
  }
});

export {
  productIndex
}